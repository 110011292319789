import React from "react"

const Iframe = ({url}) => (

    <div className="has-text-centered iframe-container">
      <iframe
          width="560px"
          height="315px"
          src={url}
          loading="lazy"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
      />
    </div>
);

const IFrameComponent = (
    {
      url = "https://www.youtube.com/embed/w-0d5q3SqKs",
      title = ""
    }) => {

  console.log(url, title)

  return(
      <div className="container">
        <div className={`columns ${title==="" && "is-hidden" }`}>
          <div
              className="column is-12"
              style={{
                paddingTop: '6%'
              }}
          >
            <div
              className="has-text-weight-semibold is-size-1-desktop is-size-2-tablet is-size-2-mobile has-text-centered"
            >
              {title}
            </div>
          </div>
        </div>
        <div
            className="row align-items-center justify-content-center"
            style={{
              margin: '1% 0 1%'
            }}
        >
          <Iframe
            url={url}
          />
        </div>
      </div>
  )
}

export default IFrameComponent
